import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";

interface Props {
  handleEditFirstName: () => void;
  handleEditLastName: () => void;
  handleEditPassword: () => void;
}
const Profile = observer(
  ({ handleEditFirstName, handleEditLastName, handleEditPassword }: Props) => {
    const { userStore, commonStore } = useStore();
    const { user } = userStore;
    const password = "•".repeat(10);

    const handleLogout = () => {
      try {
        if (commonStore.isLoggedIn()) {
          userStore.logout();
        }
      } catch (error) {
        console.log("Error logging out");
      }
    };
    return (
      <>
        <div className="text-center">
          <p className="text-[20px] font-bold text-dark-green mt-2 mb-8">
            Account info
          </p>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-4 sm:gap-3 md:gap-2  h-full w-full">
            <p className="font-bold text-dark-green">Basic info</p>
            <div className="flex flex-col gap-3">
              <div
                onClick={handleEditFirstName}
                className="flex justify-between text-[14px] font-medium text-light-gray py-1 w-full border-b-2 border-[#F7F7F7]"
              >
                {user?.firstName}
                <img src="/assets/svg/arrow/arrowRight.svg" alt="" />
              </div>
              <div
                onClick={handleEditLastName}
                className="flex justify-between text-[14px] font-medium text-light-gray py-1 w-full border-b-2 border-[#F7F7F7]"
              >
                {user?.lastName}
                <img src="/assets/svg/arrow/arrowRight.svg" alt="" />
              </div>
              <div className="flex justify-between text-[14px] font-medium text-light-gray py-1 w-full border-b-2 border-[#F7F7F7]">
                {user?.email}
              </div>
            </div>
            <p className="font-bold text-dark-green mt-2">Password</p>
            <div
              onClick={handleEditPassword}
              className="flex justify-between text-[24px] font-semibold text-dark-green py-1 w-full border-b-2 border-[#F7F7F7]"
            >
              {password}
              <img src="/assets/svg/arrow/arrowRight.svg" alt="" />
            </div>
            <div
              onClick={handleLogout}
              className="mt-3 text-[14px] font-semibold text-[#DA3333]"
            >
              Log out
            </div>
          </div>
          <div className="flex flex-col gap-3 mb-3">
            <div className="flex flex-col gap-1">
              <label className="text-[15px]  font-bold text-dark-green">
                Customer Support Email:
              </label>
              <a
                className="text-[14px] font-medium whitespace-nowrap underline"
                href="https://mail.google.com/mail/?view=cm&fs=1&to=support@energ-ai.com&su=Operator%20portal%20question&body=Dear%20EnergAI%20support,%0A%0A"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@energ-ai.com
              </a>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-[15px] font-bold text-dark-green">
                Customer Support Documentation:
              </label>
              <a
                className="text-[14px] font-medium whitespace-nowrap underline"
                href="https://energ-ai.com/support"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://energ-ai.com/support
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default Profile;
